import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {BLOCKS, INLINES, MARKS} from '@contentful/rich-text-types'
import {Heading, InlineLink, Link, River, Text, Label, type LinkProps} from '@primer/react-brand'

import type {PrimerComponentRiver} from '../../../schemas/contentful/contentTypes/primerComponentRiver'
import {ContentfulTimeline} from '../ContentfulTimeline/ContentfulTimeline'
import {documentToPlainTextString, getAnalyticsEvent} from '../../../lib/utils/analytics'
import {getPrimerIcon} from '../../../lib/utils/icons'
import styles from './ContentfulRiver.module.css'

export type ContentfulRiverProps = {
  component: PrimerComponentRiver
  linkProps?: LinkProps
  className?: string
}

export function ContentfulRiver({component, linkProps, className}: ContentfulRiverProps) {
  const {trailingComponent, label} = component.fields
  const Octicon = getPrimerIcon(label?.fields.icon)
  /**
   * We use an empty fragment if `props.content.cta` is not defined for compliance
   * with `River.Content` types (`River.Content` does not accept `null` as children).
   */
  const ctaLink =
    component.fields.callToAction !== undefined ? (
      <Link
        variant="default"
        href={component.fields.callToAction.fields.href}
        data-ref={`river-cta-link-${component.fields.callToAction.sys.id}`}
        {...(linkProps ?? {})}
        {...getAnalyticsEvent({
          action: component.fields.callToAction.fields.text,
          tag: 'link',
          context: 'CTAs',
          location: component.fields.heading,
        })}
      >
        {component.fields.callToAction.fields.text}
      </Link>
    ) : (
      <></>
    )

  const getTrailingComponent = trailingComponent
    ? () => <ContentfulTimeline component={trailingComponent} />
    : undefined

  return (
    <River className={className} align={component.fields.align} imageTextRatio={component.fields.imageTextRatio}>
      <River.Visual hasShadow={component.fields.hasShadow} className="width-full">
        {component.fields.image !== undefined ? (
          <img
            src={component.fields.image.fields.file.url}
            alt={component.fields.imageAlt ?? component.fields.image.fields.description ?? ''}
          />
        ) : component.fields.videoSrc !== undefined ? (
          <div className="position-relative" style={{paddingBottom: '55%'}}>
            {/* eslint-disable-next-line @eslint-react/dom/no-missing-iframe-sandbox */}
            <iframe
              role="application"
              className="border-0 width-full height-full position-absolute top-0 left-0"
              src={component.fields.videoSrc}
              title={component.fields.heading}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        ) : null}
      </River.Visual>

      <River.Content trailingComponent={getTrailingComponent}>
        {label && (
          <Label size={label.fields.size} color={label.fields.color} {...(Octicon ? {leadingVisual: <Octicon />} : {})}>
            {label.fields.text}
          </Label>
        )}

        {/* `h3` is the default heading level for River.Content */}
        <Heading as="h3">{component.fields.heading}</Heading>

        {
          documentToReactComponents(component.fields.text, {
            renderMark: {
              [MARKS.BOLD]: children => <em className={styles.emboldened}>{children}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (_, children) => {
                return <Text>{children}</Text>
              },
              [INLINES.HYPERLINK]: (node, children) => {
                return (
                  <InlineLink
                    data-ref={`river-inline-link-${component.sys.id}`}
                    href={node.data.uri}
                    {...getAnalyticsEvent({
                      action: documentToPlainTextString(node, ' '),
                      tag: 'hyperlink',
                      location: component.fields.heading,
                    })}
                  >
                    {children}
                  </InlineLink>
                )
              },
            },
            /**
             * 2023-09-25:
             * Primer Brand's River types are very strict, and altough we're going to
             * render a fully-compliant River.Content, types from documentToReactComponents
             * are not compatible with River.Content types. We're casting to `any` so the compiler
             * doesn't complain.
             */
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          }) as any
        }

        {ctaLink}
      </River.Content>
    </River>
  )
}

try{ ContentfulRiver.displayName ||= 'ContentfulRiver' } catch {}
try{ Octicon.displayName ||= 'Octicon' } catch {}