import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {BLOCKS, MARKS} from '@contentful/rich-text-types'
import {Testimonial, type TestimonialProps} from '@primer/react-brand'

import type {PrimerComponentTestimonial} from '../../../schemas/contentful/contentTypes/primerComponentTestimonial'

export type ContentfulTestimonialProps = Pick<TestimonialProps, 'quoteMarkColor' | 'size'> & {
  component: PrimerComponentTestimonial
}

export function ContentfulTestimonial({component, size, quoteMarkColor}: ContentfulTestimonialProps) {
  const fallbackAltText = component.fields.author?.fields.fullName ?? 'The testimonial author'
  const displayedAuthorImage = component.fields.displayedAuthorImage

  return (
    <Testimonial
      size={size ? size : component.fields.size}
      quoteMarkColor={quoteMarkColor || component.fields.quoteMarkColor}
    >
      <Testimonial.Quote>
        {
          documentToReactComponents(component.fields.quote, {
            renderMark: {
              /**
               * We use <em> to benefit from Primer Brand's special styling for <em> tags.
               */
              [MARKS.BOLD]: text => <em>{text}</em>,
            },
            renderNode: {
              /**
               * We don't need to wrap paragraphs in <p> tags.
               */
              [BLOCKS.PARAGRAPH]: (_, children) => children,
            },

            /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
             *
             * We cast to any because types from @contentful/rich-text-react-renderer are too broad for
             * the Primer Brand's Testimonial.Quote component.
             */
          }) as any
        }
      </Testimonial.Quote>

      {component.fields.author && (
        <Testimonial.Name position={component.fields.author.fields.position}>
          {component.fields.author.fields.fullName}
        </Testimonial.Name>
      )}

      {displayedAuthorImage === 'logo' && component.fields.logo !== undefined ? (
        <Testimonial.Logo>
          <img
            alt={component.fields.logo.fields.description ?? ''}
            src={component.fields.logo.fields.file.url}
            width={60}
          />
        </Testimonial.Logo>
      ) : displayedAuthorImage === 'avatar' && component.fields.author?.fields.photo !== undefined ? (
        <Testimonial.Avatar
          alt={component.fields.author.fields.photo.fields.description ?? fallbackAltText}
          src={component.fields.author.fields.photo.fields.file.url}
        />
      ) : /**
       * If neither logo nor author's photo exist or none is selected, we don't render anything.
       */
      null}
    </Testimonial>
  )
}

try{ ContentfulTestimonial.displayName ||= 'ContentfulTestimonial' } catch {}